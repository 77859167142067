import SetTokenDialog from './SetTokenDialog.svelte';
import { API_HOST } from './config';
export const SECS_IN_DAY = 24 * 60 * 60;
export const formatTime = (time) => {
    const hours = Math.floor(time / 60)
        .toString()
        .padStart(2, '0');
    const mins = Math.floor(time % 60)
        .toString()
        .padStart(2, '0');
    return hours + ':' + mins;
};
export const formatDate = (t) => {
    if (!t)
        return '';
    return new Date(t * 1000).toISOString().slice(0, 10);
};
export const formatEndDate = (t) => {
    if (!t)
        return '';
    if (t % SECS_IN_DAY === 0)
        return formatDate(t - SECS_IN_DAY);
    else
        return formatDate(t - (t % SECS_IN_DAY));
};
export const formatUntil = (seconds) => {
    if (seconds > 24 * 60 * 60)
        return (seconds / 24 / 60 / 60).toFixed(1) + ' day(s)';
    if (seconds > 60 * 60)
        return (seconds / 60 / 60).toFixed(1) + ' hour(s)';
    if (seconds > 60)
        return Math.floor(seconds / 60) + ' minutes';
    return seconds + ' seconds';
};
export const formatDuration = (minutes) => {
    if (minutes % 60 === 0) {
        if (minutes > 60)
            return Math.round(minutes / 60) + ' hours';
        return '1 hour';
    }
    return minutes + ' minutes';
};
export const formatOrdinal = (n) => {
    if (n % 10 === 1 && n % 100 !== 11)
        return n + 'st';
    if (n % 10 === 2 && n % 100 !== 12)
        return n + 'nd';
    if (n % 10 === 3 && n % 100 !== 13)
        return n + 'rd';
    return n + 'th';
};
export const sleep = async (delay) => {
    await new Promise((resolve) => setTimeout(resolve, delay));
};
export const alertErrorResponse = async (resp) => {
    try {
        const json = await resp.json();
        if (!json.name || !json.description)
            alert(`Error: ${JSON.stringify(json)}`);
        else
            alert(`Error: ${json.name}\n\n${json.description}`);
    }
    catch (_a) {
        alert(`Error: ${await resp.text()}`);
    }
};
export const createShowSetTokenDialogFn = (modal, postToken, onSetToken) => (team) => {
    modal.open(SetTokenDialog, {
        setToken: async (token) => {
            const resp = await fetch(API_HOST + `/setToken/${team}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${postToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token }),
            });
            if (resp.ok) {
                modal.close();
                onSetToken();
            }
            else
                await alertErrorResponse(resp);
        },
    }, {
        closeButton: false,
        styleContent: { padding: '24px' },
        styleWindow: {
            background: '#1c2435',
            color: '#c9d1d9',
        },
    });
};
export const fetchTokenUser = async (team, token) => {
    const resp = await fetch(API_HOST + `/tokenUser/${team}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
    if (resp.ok) {
        const j = await resp.json();
        return j.user;
    }
    else {
        console.error(`Failed to check token validity: ${resp.status} ${resp.statusText}`);
        return null;
    }
};
